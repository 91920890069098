import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { useQuery } from 'react-query'
import { Answers, Customer, PaywallContent, Plan } from '@/types/types'
import Timer from '@/components/PaywallUI/Timer'
import { Plans } from '@/components/PaywallUI/Plans'
import Review from '@/components/QuestionsUI/Review'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import Hero from '../PaywallUI/Hero'
import { getValue } from '@/helpers/getObjectValue'
import arrow from '@/assets/images/accordion-arrow.svg'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import { trackLead } from '@/helpers/facebookPixelEvents'
import Percent45Overlay from '@/components/PaywallUI/Percent45Overlay'
import { PlansHeader } from '@/components/PaywallUI/PlansHeader'
import FreeTrialOverlay from '@/components/PaywallUI/FreeTrialOverlay'
import PlansTimer from '../PaywallUI/PlansTimer'
import { PaymentPlanApi } from '@/app/api/payment-plan-api/payment-plan-api'
import { CHECKOUT_URL } from '@/constants/variables'
import { LocalStorageService } from '@/services/local-storage-service'
import Image from '../Image/Image'

const transition = '300ms all, 300ms all'
const transitionStylesModal = {
  entered: {
    top: '0px',
  },
  exited: {
    top: '100%',
  },
}
const defaultStyleModal = {
  transition,
  top: '100%',
}

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export interface PaywallStatus {
  currentStep: number
  showModal: boolean
  wasShowing?: boolean
}

export default function Paywall(props: PaywallProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const nodeRef = useRef(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const planElement = useRef<HTMLDivElement>(null)
  const topPage = useRef<HTMLDivElement>(null)

  const [activePlan, setActivePlan] = useState(1)
  const [paywallStatus, setPaywallStatus] = useState<PaywallStatus>(
    (LocalStorageService.get('paywallStatus') as PaywallStatus) || {
      currentStep: 0,
      showModal: false,
    },
  )

  const storedData = LocalStorageService.get('quizResult')
  const answers: Answers = storedData || false
  const goal = getValue('main-goal', answers)
  const goalWeight = getValue('goal-weight', answers)

  const [customer] = useState<Customer>({
    city: LocalStorageService.get('city'),
    email: getValue('email', answers) as string,
    address: LocalStorageService.get('city'),
    lastName: (getValue('your-lastname', answers) as string) ?? '', // 'John',
    firstName: (getValue('your-name', answers) as string) ?? '', // 'John',
    postalCode: LocalStorageService.get('postalCode') || '',
    countryCode: LocalStorageService.get('countryCode') || '',
    customerPortalID: LocalStorageService.get('customerPortalId') || '',
  })

  const isFreeTrial = paywallStatus.currentStep >= 2
  const isDiscountPrice = paywallStatus.currentStep === 1

  const { data: plans, isLoading: isLoadingPlans } = useQuery({
    queryKey: ['plans'],
    queryFn: () => PaymentPlanApi.fing(),
    select(data) {
      const formatData = data.items.map((item) => ({
        name: item.name,
        ...item.plans.find((plan) => plan.type === 'stripeV1'),
      }))

      return formatData as Plan[]
    },
  })

  const { data: paywallContent } = useQuery({
    queryKey: ['paywallContent'],
    enabled: !!props.lang && !!props.paywallFile,
    queryFn: async () => {
      const response: PaywallContent = await import(
        `../../locales/${props.lang}/${props.paywallFile}`
      )
      return response
    },
    onError(error) {
      console.error('Error fetching paywall data:', error)
    },
  })

  useEffect(() => {
    trackLead()
  }, [])

  useEffect(() => {
    if (!answers) {
      navigate('/')
    }
  }, [answers])

  const handleCheckout = () => {
    const params = {
      planId: plans?.[activePlan]?._id ?? '',
      ...customer,
    }

    const checkoutUrl = new URL(CHECKOUT_URL)

    for (const key in params) {
      checkoutUrl.searchParams.append(key, params[key])
    }

    const dietVegan = getValue('type-of-diet', answers) !== 'Traditional'

    if (isFreeTrial) checkoutUrl.searchParams.append('isFreeTrial', 'true')
    if (isDiscountPrice) checkoutUrl.searchParams.append('isDiscountPrice', 'true')
    if (dietVegan) checkoutUrl.searchParams.append('vegan', 'true')
    if (paywallStatus.currentStep === 0) handleChangePaywallStatus(true, 1)

    window.location.href = checkoutUrl.href
  }

  const handleSavePaywallStatus = (modal: boolean, step?: number) => {
    LocalStorageService.set('paywallStatus', {
      currentStep: step ?? paywallStatus.currentStep,
      showModal: modal,
    })
  }

  const handleChangePaywallStatus = (modal: boolean, step?: number) => {
    setPaywallStatus({ currentStep: step ?? paywallStatus.currentStep, showModal: modal })
    handleSavePaywallStatus(modal, step)
  }

  const handleScrollToPlan = () => {
    const planOffsetTop = planElement.current?.offsetTop || 0
    containerRef.current?.scroll(0, planOffsetTop - 70)
  }

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  if (isLoadingPlans)
    return (
      <div className="fixed top-0 z-30 left-0 bg-white w-full h-[calc(100%-60px)]">
        <div className="spinner z-30"></div>
      </div>
    )

  return (
    answers && (
      <Layout>
        <div ref={containerRef} className="overflow-y-auto min-h-full p-4 pt-0 overflow-x-hidden">
          <div ref={topPage}></div>
          <div className="pb-2 pt-2 sticky top-0 bg-white z-50  flex items-center justify-between">
            <div>
              {paywallStatus.currentStep === 0 && (
                <p className="text-xs2 text-dark leading-5">
                  <b>61%</b> discount reserved for
                </p>
              )}
              {isDiscountPrice && (
                <p className="text-xs2 text-dark leading-5">
                  <b>71%</b> discount reserved for
                </p>
              )}
              {paywallStatus.currentStep === 2 && (
                <p className="text-xs2 text-dark leading-5">Trial reserved for</p>
              )}

              <Timer />
            </div>
            <Button onClick={handleScrollToPlan} variant="plan" className="w-[55%]">
              {isFreeTrial ? 'Start trial' : t('get my plan')}
            </Button>
          </div>
          {isDiscountPrice && (
            <>
              <p className="text-center line-through">
                Previous discount: <span className="text-green2">61%</span>
              </p>
              <div className="bg-[#8DECB4] flex items-center mt-3 mb-6 py-3 px-4 rounded-2xl">
                <span className="text-[50px] mr-2">🎁</span>
                <p className="text-center font-bold">
                  Get your Personal Wall Pilates Plan with up to{' '}
                  <span className="text-plan">71%</span> discount
                </p>
              </div>
            </>
          )}
          <Hero answers={answers} />
          <p className="text-xs2 leading-6 text-[#B3C1CD] mb-7">
            This is not a guarantee or promise of results
          </p>
          <div
            id="plan"
            ref={planElement}
            className="pt-4.5 pb-8 px-6 rounded-r15 border border-borderInput"
          >
            <PlansHeader
              paywallContent={paywallContent}
              isFreeTrial={isFreeTrial}
              goal={goal}
              goalWeight={goalWeight}
            />
            <PlansTimer />
            {plans && paywallContent && (
              <Plans
                activePlan={activePlan}
                isDiscountPrice={isDiscountPrice}
                isFreeTrial={isFreeTrial}
                handleCheckout={handleCheckout}
                handlePlan={handlePlan}
                paymentMethod={props.paymentMethod}
                descriptionTrial={paywallContent.plans?.descriptionTrial}
                plans={plans}
              />
            )}
          </div>
          {paywallContent?.checkoutBlock && (
            <div className="pt-5 pb-5 px-2 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-4 text-center mb-4"
                dangerouslySetInnerHTML={{ __html: paywallContent.checkoutBlock.title }}
              ></h3>
              <div className="flex items-center justify-between flex-wrap px-7">
                {paywallContent?.checkoutBlock.items?.map((el) => (
                  <Image className="max-h-9 my-3 mx-2.5" key={el} src={el} alt="Image" />
                ))}
                <span className="w-8"></span>
              </div>
            </div>
          )}

          {paywallContent?.benefitsBlock && (
            <div className="pt-5 pb-8 px-3 pr-8 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-3 -mr-8 text-center mb-7"
                dangerouslySetInnerHTML={{ __html: paywallContent.benefitsBlock.title }}
              ></h3>
              {paywallContent?.benefitsBlock?.items && (
                <ul className="list-none">
                  {paywallContent?.benefitsBlock?.items?.map((el, index) => (
                    <li className="flex items-center mt-6" key={index}>
                      <div className="max-w-11 min-w-11 h-11 rounded-full bg-checked flex items-center justify-center mr-2.5">
                        <Image className="w-6" src={el.src} alt="Icon" />
                      </div>
                      <p className="font-semibold leading-5">{el.text}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {paywallContent?.featuredBlock && (
            <div className="pt-5 pb-5 px-2 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-4 text-center mb-6"
                dangerouslySetInnerHTML={{ __html: paywallContent.featuredBlock.title }}
              ></h3>
              <div className="flex items-center justify-center flex-wrap">
                {paywallContent?.featuredBlock.items?.map((el) => (
                  <Image className="max-h-6 m-3 mx-2" key={el} src={el} alt="Image" />
                ))}
              </div>
            </div>
          )}

          {paywallContent?.testimonialsBlock && (
            <div className="mt-7 ">
              <h3 className="px-12  min-[364px]:px-14 min-[394px]:px-16 min-[412px]:px-20 text-center text-xl font-extrabold bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent mb-2">
                {paywallContent?.testimonialsBlock.title}
              </h3>
              {paywallContent?.testimonialsBlock?.reviews?.map((el) => (
                <Review
                  key={el.title + el.name}
                  title={el.title}
                  quote={el.quote}
                  stars={el.stars}
                  src={el.src}
                  srcWebp={el.srcWebp}
                  name={el.name}
                  class={el.class}
                  trustpilot={el.trustpilot}
                  mainTitle={el.mainTitle}
                />
              ))}
            </div>
          )}
          {paywallContent?.faqBlock && (
            <div className="pt-4 pb-8 px-5 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12 max-w-64 mx-auto  font-extrabold text-center mt-4 mb-7"
                dangerouslySetInnerHTML={{ __html: paywallContent.faqBlock.title }}
              ></h3>
              <Accordion type="single" collapsible>
                {paywallContent?.faqBlock.items?.map((item, index) => (
                  <AccordionItem
                    key={index}
                    className="border-b border-border"
                    value={`item-${index}`}
                  >
                    <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                      {item.title}
                      <Image
                        className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                        src={arrow}
                        alt="arrow"
                      />
                    </AccordionTrigger>
                    <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                      <p className="pb-7 text-left text-light text-md">{item.text}</p>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}

          <div className="pt-4.5 pb-8 px-6 rounded-r15 border border-borderInput mt-7">
            <PlansHeader
              paywallContent={paywallContent}
              isFreeTrial={isFreeTrial}
              goal={goal}
              goalWeight={goalWeight}
            />
            <PlansTimer />
            {plans && paywallContent && (
              <Plans
                activePlan={activePlan}
                isDiscountPrice={isDiscountPrice}
                isFreeTrial={isFreeTrial}
                handleCheckout={handleCheckout}
                handlePlan={handlePlan}
                paymentMethod={props.paymentMethod}
                descriptionTrial={paywallContent.plans?.descriptionTrial}
                plans={plans}
              />
            )}
          </div>
          {paywallContent?.moneyBackBlock && (
            <div className="pt-7 pb-8 px-5 rounded-r15 border border-borderInput mt-7">
              <Image
                className="w-13 mx-auto"
                src={paywallContent?.moneyBackBlock.img}
                alt="100% money-back guarantee"
              />
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12 max-w-64 mx-auto  font-extrabold text-center mt-4 mb-5"
                dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.title }}
              ></h3>
              <p className="w-full text-md text-center leading-5">
                {paywallContent?.moneyBackBlock.content}{' '}
                <Link
                  className="transition-all hover:text-primary text-blue underline"
                  to="/money-back"
                  dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.link }}
                ></Link>
              </p>
            </div>
          )}
          {paywallContent?.copyright && (
            <p
              className="px-4 py-7 pb-3 text-center text-xs2 font-medium text-grayB [&>span]:px-px"
              dangerouslySetInnerHTML={{ __html: paywallContent.copyright }}
            ></p>
          )}

          <Transition
            nodeRef={nodeRef}
            timeout={100}
            onExited={() => topPage.current?.scrollIntoView()}
            in={isDiscountPrice && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <Percent45Overlay
                    isShow={paywallStatus.showModal && isDiscountPrice}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>

          <Transition
            nodeRef={nodeRef}
            onExited={handleScrollToPlan}
            timeout={100}
            in={paywallStatus.currentStep === 2 && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <FreeTrialOverlay
                    isShow={paywallStatus.showModal && paywallStatus.currentStep === 2}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>
        </div>
      </Layout>
    )
  )
}
