import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'
import { getActivePlanPrice } from '@/helpers/getPlanPrice'
import { purchaseFacebookApi } from '@/helpers/facebookApi'
import { PaymentPlan } from '@/types/types'

export const trackPageView = () => {
  ReactPixel.pageView()
}
export const trackPageViewContent = () => {
  ReactPixel.track('ViewContent')
}
export const trackLead = () => {
  ReactPixel.track('Lead')
  amplitude.track('paywall_view')
}

export const trackInitCheckout = (plan: string, event: string) => {
  // const quizVersion = localStorage.getItem('quizVersion') === 'true'
  ReactPixel.track('InitiateCheckout')
  // amplitude.track(event, { plan, quiz: !quizVersion ? 'betterme' : 'Weight lose' })
  amplitude.track('checkout_view', { plan, quiz: 'Weight lose', event })
}

export const trackPurchase = (withFB = true) => {
  const prices = getActivePlanPrice()
  const AmplitudeID = amplitude.getSessionId()

  if (withFB) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fbq(
      'track',
      'Purchase',
      {
        currency: 'USD',
        value: prices.price,
        predicted_ltv: prices.predictedPrice,
      },
      { eventID: AmplitudeID },
    )
    setTimeout(() => {
      purchaseFacebookApi()
    }, 1000)
  }

  amplitude.track('Purchase', { prices })
}

export const selectPaymentMethod = (method: string) => {
  amplitude.track('select_payment_method', { method })
}
export const clickPurchaseButton = (method: string) => {
  amplitude.track('click_purchase_button', { method })
}
export const backCheckout = () => {
  amplitude.track('back_checkout')
}
export const trackPaymentErrors = (payment: string, e: any) => {
  amplitude.track(`payment_error:${payment}`, { error: e })
}
export const trackPayment = (payment: string, message: any) => {
  amplitude.track(`payment:${payment}`, { message })
}
export const trackPlan = (plan: PaymentPlan) => {
  amplitude.track('payment:selectPlane', plan)
}
export const trackEmail = (event: string, email: string) => {
  amplitude.track(event, { email })
}

export const trackEvent = (event: string) => {
  amplitude.track(event)
}

export const setUserId = (id: string) => {
  amplitude.setUserId(id)
}
